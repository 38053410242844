<template>
    <a-layout id="components-layout-demo-custom-trigger">
        <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
            <div class="logo" v-show="!collapsed">申怡伴读管理后台</div>

            <!--  :defaultOpenKeys="[$route.path.split('/')[2]]"
                  :defaultSelectedKeys="[$route.path.split('/')[1]]"
                  :selectedKeys="selectedKeys"
             -->
            <a-menu
                :defaultOpenKeys="['1']"
                :defaultSelectedKeys="['home']"
                :selectedKeys="selectedKeys"
                mode="inline"
                :open-keys="openKeys"
                theme="dark"
                @openChange="onOpenChange"
                @click="menuClick"
            >
                <a-menu-item key="home" @click="() => $router.push('/home')">
                    <a-icon type="home"/>
                    <span>首页</span>
                </a-menu-item>

                <a-sub-menu :key="item.code" v-for="item in powerArr">
                    <span slot="title">
                        <a-icon :type="item.icon"/>
                        <span>{{ item.title }}</span>
                    </span>
                    <a-menu-item :key="e.code" v-for="e in item.children">
                        <router-link :to="e.url">{{ e.title }}</router-link>
                    </a-menu-item>
                </a-sub-menu>
            </a-menu>
        </a-layout-sider>
        <a-layout>
            <a-layout-header style="background: #fff; padding: 0">
                <a-icon
                    class="trigger"
                    :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                    @click="() => (collapsed = !collapsed)"
                />
                <!-- 面包屑 -->
                <a-breadcrumb separator=">" class="flex1">
                    <a-breadcrumb-item
                        v-for="(item, index) of $route.matched"
                        :key="index"
                    >
            <span
                style="font-size: 16px"
                @click="breadcrumbClick(item, index)"
            >{{ item.meta.title }}</span
            >
                    </a-breadcrumb-item>
                </a-breadcrumb>
                <span class="login_out">
          <div>
            <a-dropdown>
              <span
                  class="ant-dropdown-link"
                  @click="(e) => e.preventDefault()"
              >
                <img :src="headUrl" alt="头像"/>
                {{ userName }}
              </span>
              <a-menu slot="overlay" @click="loginOut">
                <a-menu-item key="1"> 退出登录 </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </span>
            </a-layout-header>
            <!-- 内容区域 -->
            <a-layout-content
                :style="{
          margin: '24px 16px',
          padding: '24px',
          background: '#fff',
          minHeight: '280px',
        }"
            >
                <router-view/>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>
<script>
import {UserLogoutApi} from "@/request/api/login";

export default {
    created() {
        this.selectedKeys.push(this.$route.path.split("/")[2]);
        this.openKeys = [];
        this.openKeys.push(this.$route.path.split("/")[1]);
        // 处理侧栏收起状态
        this.$watch("collapsed", () => {
            this.$store.commit("sidebarType", this.collapsed);
        });
        this.userName = localStorage.getItem("userName"); // 设置用户名
        this.headUrl = localStorage.getItem("headUrl"); // 设置头像

        // 权限数组
        this.powerArr = JSON.parse(localStorage.getItem("powerListArr"));
    },
    data() {
        return {
            collapsed: false, // 侧边导航栏是否展开
            userName: "139xxxxxxx",
            headUrl: "",
            rootSubmenuKeys: [
                "rolePower",
                "coursManage",
                "indexManage",
                "videoManage",
                "teachersManage",
                "crm",
                "orderformManage",
                "circleManage",
                "marketingCenter",
                "userFeedback",
                "setUp",
                "systemManage",
                "physicalCard",
                'userManage',
                'saleManage',
                'appletsManage',
                'noticeManage',
                'reportFormManage',
                'researchLearning'
            ],
            selectedKeys: [], // 当前选中的menu-item的key数组
            openKeys: ["rolePower"], // 展开的 sub-menu 的 key 数组
            // 权限数组
            powerArr: [
                {
                    code: "rolePower",
                    title: "角色和权限",
                    icon: "container",
                    children: [
                        {
                            code: "roleManage",
                            title: "角色管理",
                            url: "/rolePower/roleManage",
                        },
                        {
                            code: "personManage",
                            title: "人员管理",
                            url: "/rolePower/personManage",
                        },
                    ],
                },
                {
                    code: "coursManage",
                    title: "课程管理",
                    icon: "form",
                    children: [
                        {
                            code: "courseList",
                            title: "课程列表",
                            url: "/coursManage/courseList",
                        },
                        {
                            code: "courseTag",
                            title: "课程标签",
                            url: "/coursManage/courseTag",
                        },
                        {
                            code: "courseCategory",
                            title: "课程分类",
                            url: "/coursManage/courseCategory",
                        },
                    ],
                },
                {
                    code: "videoManage",
                    title: "免费视频管理",
                    icon: "form",
                    children: [
                        {
                            code: "freeViedo",
                            title: "免费课程",
                            url: "/videoManage/freeViedo",
                        },
                        {
                            code: "courseTag",
                            title: "课程分类",
                            url: "/videoManage/videoClassify",
                        },
                    ],
                },
                {
                    code: "indexManage",
                    title: "首页管理",
                    icon: "form",
                    children: [
                        {
                            code: "indexConfig",
                            title: "首页配置",
                            url: "/indexManage/indexConfig",
                        },
                        {
                            code: "groupingManage",
                            title: "分组管理",
                            url: "/indexManage/groupingManage",
                        },
                        {
                            code: "indexCategory",
                            title: "首页分类",
                            url: "/indexManage/indexCategory",
                        },
                    ],
                },
                {
                    code: "noticeManage",
                    title: "消息管理",
                    icon: "form",
                    children: [
                        {
                            code: "noticeIndex",
                            title: "预约直播",
                            url: "/noticeManage/noticeIndex",
                        }
                    ],
                },
                {
                    code: "appletsManage",
                    title: "小程序管理",
                    icon: "form",
                    children: [
                        {
                            code: "liveBroadcast",
                            title: "预约直播",
                            url: "/appletsManage/liveBroadcast",
                        }
                    ],
                },
                {
                    code: "teachersManage",
                    title: "师资管理",
                    icon: "form",
                    children: [
                        {
                            code: "teacherList",
                            title: "老师列表",
                            url: "/teachersManage/teacherList",
                        },
                        {
                            code: "teacherTag",
                            title: "老师标签",
                            url: "/teachersManage/teacherTag",
                        },
                    ],
                },
                {
                    code: "userManage",
                    title: "用户中心",
                    icon: "icon",
                    children: [
                        {
                            code: "userList",
                            title: "用户管理列表",
                            url: "/userManage/userList",
                        },
                        {
                            code: "legalRightList",
                            title: "用户权益变更",
                            url: "/userManage/legalRightList",
                        },
                        {
                            code: "attributedToList",
                            title: "用户归属划分",
                            url: "/userManage/attributedToList",
                        },
                        {
                            code: "ownershipList",
                            title: "用户归属变更",
                            url: "/userManage/ownershipList",
                        },
                        {
                            code: "playDataList",
                            title: "用户播放数据",
                            url: "/userManage/playDataList",
                        },
                    ],
                },
                {
                    code: "crm",
                    title: "CRM",
                    icon: "table",
                    children: [
                        {
                            code: "clientManage",
                            title: "客户管理",
                            url: "/crm/clientManage",
                        },
                        {
                            code: "classManage",
                            title: "班级管理",
                            url: "/crm/classManage",
                        },
                        {
                            code: "studyData",
                            title: "学习数据",
                            url: "/crm/studyData",
                        },
                    ],
                },
                {
                    code: "orderformManage",
                    title: "订单管理",
                    icon: "warning",
                    children: [
                        {
                            code: "orderformList",
                            title: "订单列表",
                            url: "/orderformManage/orderformList",
                        },
                        {
                            code: "thirdPartyOrder",
                            title: "抖店订单列表",
                            url: "/orderformManage/thirdPartyOrder",
                        },
                        {
                            code: "wxThirdPartyOrder",
                            title: "微信小店订单列表",
                            url: "/orderformManage/wxThirdOrder",
                        }
                    ],
                },
                {
                    code: "circleManage",
                    title: "圈子管理",
                    icon: "form",
                    children: [
                        {
                            code: "talkList",
                            title: "话题发布",
                            url: "/circleManage/talkList.vue",
                        },
                        {
                            code: "contentList",
                            title: "内容列表",
                            url: "/circleManage/contentList",
                        },
                    ],
                },
                {
                    code: "saleManage",
                    title: "销售中心",
                    icon: "saleManage",
                    children: [
                        {
                            code: "channelSales",
                            title: "渠道销售数据",
                            url: "/saleManage/channelSales",
                        },
                        {
                            code: "/saleManage/userPromotion",
                            title: "用户推广数据",
                            url: "/saleManage/userPromotion"
                        },
                    ],
                },
                {
                    code: "reportFormManage",
                    title: "报表中心",
                    icon: "reportFormManage",
                    children: [
                        {
                            code: "transactionIndex",
                            title: "业务数据报表",
                            url: "/reportFormManage/transactionIndex",
                        },
                        {
                            code: "contentIndex",
                            title: "内容数据报表",
                            url: "/reportFormManage/contentIndex"
                        },
                        {
                            code: "operateIndex",
                            title: "运营数据报表",
                            url: "/reportFormManage/operateIndex"
                        },
                    ],
                },
                {
                    code: "marketingCenter",
                    title: "营销中心",
                    icon: "exception",
                    children: [
                        {
                            code: "inviteCode",
                            title: "邀请码",
                            url: "/marketingCenter/inviteCode",
                        },
                        {
                            code: "/marketingCenter/InviteMember",
                            title: "线下码",
                            url: "/marketingCenter/InviteMember"
                        },
                        {
                            code: "/marketingCenter/InviteOnline",
                            title: "线上码",
                            url: "/marketingCenter/InviteOnline"
                        },
                        {
                            code: "memberManage",
                            title: "会员管理",
                            url: "/marketingCenter/memberManage",
                        },
                        {
                            code: "proxyManage",
                            title: "代理管理",
                            url: "/marketingCenter/proxyManage",
                        },
                        {
                            code: "couponManage",
                            title: "优惠券管理",
                            url: "/marketingCenter/couponManage",
                        },
                        {
                            code: "distributors",
                            title: "营销中心",
                            url: "/marketingCenter/distributors",
                        },
                    ],
                },
                {
                    code: "physicalCard",
                    title: "商品中心",
                    icon: "exception",
                    children: [
                        {
                            code: "physicalCardList",
                            title: "渠道直销商品",
                            url: "/physicalCard/physicalCardList",
                        },
                    ],
                },
                {
                    code: "userFeedback",
                    title: "用户反馈",
                    icon: "exception",
                    children: [
                        {
                            code: "feedbackList",
                            title: "反馈列表",
                            url: "/userFeedback/feedbackList",
                        },
                    ],
                },
                {
                    code: "setUp",
                    title: "设置",
                    icon: "setting",
                    children: [
                        {
                            code: "commonProblem",
                            title: "常见问题",
                            url: "/setUp/commonProblem",
                        },
                    ],
                },
                {
                    code: "systemManage",
                    title: "系统管理",
                    icon: "solution",
                    children: [
                        {
                            code: "systemAbnormal",
                            title: "系统异常日志管理",
                            url: "/systemManage/systemAbnormal",
                        },
                        {
                            code: "menuManage",
                            title: "菜单管理",
                            url: "/systemManage/menuManage",
                        },
                        {
                            code: "journalManage",
                            title: "日志管理",
                            url: "/systemManage/journalManage",
                        },
                        {
                            code: "globalConstants",
                            title: "全局常量",
                            url: "/systemManage/globalConstants",
                        },
                    ],
                },
                {
                    code: "globalConstants",
                    title: "全局常量",
                    icon: "solution",
                    children: [
                        {
                            code: "globalConstant",
                            title: "全局常量",
                            url: "/systemManage/globalConstants",
                        },
                    ],
                }
            ],
        };
    },
    methods: {
        // 面包屑的按钮
        breadcrumbClick(item, index) {
            if (index != 0) {
                this.$router.push(item.path);
            }
        },
        // 切换
        toggleCollapsed() {
            this.collapsed = !this.collapsed;
        },
        // SubMenu 展开/关闭的回调
        onOpenChange(openKeys) {
            const latestOpenKey = openKeys.find(
                (key) => this.openKeys.indexOf(key) === -1
            );
            if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                this.openKeys = openKeys;
            } else {
                this.openKeys = latestOpenKey ? [latestOpenKey] : [];
            }
        },
        menuClick(item) {
            this.selectedKeys = []; // 清空选中的子菜单的key 数组
            this.selectedKeys.push(item.key);
        },
        // 退出登录
        loginOut() {
            UserLogoutApi().then(({code}) => {
                if (code === 200) {
                    localStorage.clear();
                    this.$router.push("/login");
                }
            });
        },
    },
};
</script>
<style lang="less" scoped>
// 退出登录
.login_out {
    margin-right: 24px;

    div {
        img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 10px;
        }

        .ant-dropdown-trigger {
            cursor: pointer;
        }
    }
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
    text-align: center;
}

.ant-layout.ant-layout-has-sider {
    height: 100vh;
}

.ant-layout-header {
    display: flex;
    align-items: center;
}

.ant-layout-content {
    padding: 0 !important;
    position: relative;
}

#components-layout-demo-custom-trigger .trigger {
    font-size: 18px;
    // line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
    height: 32px;
    line-height: 32px;
    // background: rgba(255, 255, 255, 0.2);
    margin: 16px;
    font-size: 18px;
    text-align: center;
    color: #fff;
}

/deep/ .ant-breadcrumb {
    cursor: pointer;
}
</style>
